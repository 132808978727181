import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { WebapiService } from '../webapi.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit {
  applicationList: any;
  candidate: any;
  imageUrl:any;
  countList: any;
  formExcelData: any={};
  formExcelDatav2: any={};
  clientList: any;
  smallModalContent: any ;
  clientprocessList: any;
  filter:any={};
  total:number;
  p:number = 1;
  totalAppCount: any;
  loading:boolean;
  teamName:any
  loginType: any;
  mangList: any;
  statusData:any={};
  approveCounter: boolean =true;
  inviteUrl:any;
  statusArr:any;
  finalStatusArr: any=[];
  reportUrl: any;
  isLoading: boolean;
  reportData:any={};
  filterData:any={};
  loginId: any;
  fileString: any;
  pdfCounter: boolean = false;
  imageCounter: boolean = false;
  fileData:any={};

  selectedFiles: FileList;
  progressInfos = [];

  constructor(private WebApiService:WebapiService,private activatedRoute:ActivatedRoute,
    private router:Router, private modalService: NgbModal,private sanitizer: DomSanitizer) {
      this.imageUrl = this.WebApiService.imageUrl;
      this.inviteUrl = this.WebApiService.inviteUrl;
      this.reportUrl = this.WebApiService.reportUrl;
     }

  ngOnInit(): void {
    this.teamName = sessionStorage.getItem("teamName");
    this.loginType = sessionStorage.getItem("type");
    this.loginId = sessionStorage.getItem("loginId");
    if(this.loginType == 3){
      this.statusArr = ['ACCEPTED','REJECTED','INSUFF'];
    }
    else{
      this.statusArr = ['NEW','INPROGRESS','HOLD','COMPLETED','STOPPED'];
    }
    this.filter = {
      pageNo:1,
      size:10,
      status:'',
      search:'',
      clientId:'',
      created:''
    }
    this.getClient();
    if(this.loginType == 1){
      this.getAllApplication();
      this.getApplicationCountByStatus();
    }
    else if(this.loginType == 4 && this.teamName == "Case Initiation Team"){
      this.getAllApplicationV2();
      this.getApplicationCountByStatusV2();
      this.getAllManager();
    }
    else if(this.loginType ==4 && this.teamName == "Processing Team"){
      this.getAllApplicationByEmp();
      this.getApplicationCountByStatusByEmp();
    }
    else if(this.loginType == 3){
      this.filter.clientId = this.loginId
      this.getAllApplication();
      this.getApplicationCountByStatus();
    }
  }

  getResp(e:any){
   if(e.target.value == "APPROVED"){
    this.approveCounter = true;
   }
   else{
    this.approveCounter = false;
   }
  }
  setStatus(data:any){
    data.team = this.teamName;
    this.WebApiService.insertApplication(data).subscribe((res: any) => {
      console.log(res);
      if(res.status == "ok"){
        alert(res.msg);
        if(this.loginType == 1){
          this.getAllApplication();
          this.getApplicationCountByStatus();
        }
        else if(this.loginType == 4 && this.teamName == "Case Intitation Team"){
          this.getAllApplicationV2();
          this.getApplicationCountByStatusV2();
          this.getAllManager();
        }
        else if(this.loginType ==4 && this.teamName == "Processing Team"){
          this.getAllApplicationByEmp();
          this.getApplicationCountByStatusByEmp();
        }
        else if(this.loginType == 3){
          this.filter.clientId = this.loginId
          this.getAllApplication();
          this.getApplicationCountByStatus();
        }
      }
      else{
        alert(res.msg);
      }

    });
  }

  checkForDoubleCase(){

  }

  getAllManager(){
    this.WebApiService.getAllManager().subscribe((res:any)=>{
      // console.log(res);
      this.mangList = res.user;
    });
  }

  inviteCandidate(id:any,candName:any,clientName:any,email:any){
   let val = {
    appId:id,
    candName:candName,
    clientName:clientName,
    url:this.inviteUrl,
    candidateEmail:email
   }
   this.WebApiService.inviteEmail(val).subscribe((res:any)=>{
    console.log(res);
    if(res.status == "ok"){
      alert(res.msg);
    }
    else{
      alert("something went wrong");
    }
  });
  }

  changeAppStatus(appId:any,e:any){
    //  console.log(appId,status);
     let val = {
       _id:appId,
       status:e.target.value
     }
     this.WebApiService.insertApplication(val).subscribe((res: any) => {
      console.log(res);
      if(res.status == "ok"){
        alert(res.msg);
        if(this.loginType == 1){
          this.getAllApplication();
          this.getApplicationCountByStatus();
        }
        else if(this.loginType == 4 && this.teamName == "Case Intitation Team"){
          this.getAllApplicationV2();
          this.getApplicationCountByStatusV2();
        }
        else if(this.loginType ==4 && this.teamName == "Processing Team"){
          this.getAllApplicationByEmp();
          this.getApplicationCountByStatusByEmp();
        }
        else if(this.loginType == 3){
          this.filter.clientId = this.loginId
          this.getAllApplication();
          this.getApplicationCountByStatus();
        }
      }
      else{
        alert(res.msg);
      }

    });

  }

  genrateReportV2(data:any){
    if(data.temp == "IR"){
      data.interimDate = data.date;
      data.result = data.result;
    }
    else if(data.temp == "SR"){
      data.supplimentryDate = data.date;
      data.result = data.result;

    }
    else if(data.temp == "FR"){
      data.finalDate = data.date;
      data.result = data.result;

    }
   this.WebApiService.insertApplication(data).subscribe((res: any) => {
    console.log(res);
    if(res.status == "ok"){
      this.genrateReport(data._id,data.temp);
    }
    else{
      alert(res.msg);
    }

  });

  }

  genrateReport(id:any,template:any){
    // this.isLoading =true;
   let val = {
    appId:id,
    temp:template
   }
   this.WebApiService.genrateReport(val).subscribe((res: any) => {
    console.log(res);

    if(res.status == "ok"){
      // this.isLoading = false;
      alert("Report Generated");
      this.modalService.dismissAll();
      if(this.loginType == 1){
        this.getAllApplication();
        this.getApplicationCountByStatus();
      }
      else if(this.loginType == 4 && this.teamName == "Case Intitation Team"){
        this.getAllApplicationV2();
        this.getApplicationCountByStatusV2();
      }
      else if(this.loginType ==4 && this.teamName == "Processing Team"){
        this.getAllApplicationByEmp();
        this.getApplicationCountByStatusByEmp();
      }
      else if(this.loginType == 3){
        this.filter.clientId = this.loginId
        this.getAllApplication();
        this.getApplicationCountByStatus();
      }
      window.open(`${this.reportUrl}${res.path}`,"_blank");
    }
    else{
      alert("something went wrong");
    }
  });

  }

  getClient() {
    this.WebApiService.getAllClient().subscribe((res: any) => {
      this.clientList = res.user;
    });
  }

  getAllApplication(){
    this.loading =true;
    this.WebApiService.getAllApplication(this.filter).subscribe((res:any)=>{
   this.applicationList = res.data;
   this.total = res.total;
   this.loading =false
   for (const ap of this.applicationList) {
    let today:any = new Date();
    let otherDate:any = new Date(ap.tatEnd);
    let diffTime = Math.abs(today - otherDate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (today > otherDate) {
      ap.tatStatus = `${diffDays} days delayed`;
    } else if (today < otherDate) {
      ap.tatStatus = `${diffDays} days left`;
    } else {
      ap.tatStatus = `last day of tat`;
    }
     }

     if(this.applicationList[0]?._id){
       this.getAppById(this.applicationList[0]?._id);
     }

    })
  }

  getAllApplicationV2(){
    this.loading =true;
    this.WebApiService.getAllApplicationInit(this.filter).subscribe((res:any)=>{
   this.applicationList = res.data;
   this.total = res.total;
   this.loading =false
   for (const ap of this.applicationList) {
    let today:any = new Date();
    let otherDate:any = new Date(ap.tatEnd);
    let diffTime = Math.abs(today - otherDate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (today > otherDate) {
      ap.tatStatus = `${diffDays} days delayed`;
    } else if (today < otherDate) {
      ap.tatStatus = `${diffDays} days left`;
    } else {
      ap.tatStatus = `last day of tat`;
    }
     }

     if(this.applicationList[0]?._id){
       this.getAppById(this.applicationList[0]?._id);
     }
    })
  }

  getAllApplicationByEmp(){
    this.loading =true;
    this.WebApiService.getAllApplicationByEmp(this.filter).subscribe((res:any)=>{
    //  console.log(res);
   this.applicationList = res.data;
   this.total = res.total;
   this.loading =false
   for (const ap of this.applicationList) {
    let today:any = new Date();
    let otherDate:any = new Date(ap.tatEnd);
    let diffTime = Math.abs(today - otherDate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (today > otherDate) {
      ap.tatStatus = `${diffDays} days delayed`;
    } else if (today < otherDate) {
      ap.tatStatus = `${diffDays} days left`;
    } else {
      ap.tatStatus = `last day of tat`;
    }
     }

     if(this.applicationList[0]?._id){
       this.getAppById(this.applicationList[0]?._id);
     }
    //  console.log(this.applicationList,'uu');

    })
  }

  onTableDataChange(event: any) {
    this.filter.pageNo = event;
    if(this.loginType == 1){
      this.getAllApplication();
    }
    if(this.loginType == 4 && this.teamName == "Case Intitation Team"){
      this.getAllApplicationV2();
    }
    if(this.loginType == 4){
      this.getAllApplicationByEmp();
    }
    this.p = event;
  //   window.scrollTo({
  //    top: 0,
  //    behavior: 'smooth'
  //  });
 }
  getApplicationCountByStatus(){
    this.WebApiService.getApplicationCountByStatus(this.filter).subscribe((res:any)=>{
    // console.log(res);
    if(res.data.length){
      const obj={};
      for (const key of res.data[0]?.count_per_field) {
            obj['ALL'] = res.total
            obj[key.k] = key.v;
       }
       this.countList = obj;
    }
  })
  }

  getApplicationCountByStatusV2(){
    this.WebApiService.getApplicationCountByStatusInit().subscribe((res:any)=>{
    // console.log(res);
    if(res.length){
      const obj={};
      for (const key of res[0]?.count_per_field) {
            obj['ALL'] = res[0]?.total_count
            obj[key.k] = key.v;
       }
       this.countList = obj;
    }
  })
  }

  getApplicationCountByStatusByEmp(){
    this.WebApiService.getApplicationCountByStatusByEmp().subscribe((res:any)=>{
    // console.log(res);
    if(res.length){
      const obj={};
      for (const key of res[0]?.count_per_field) {
            obj['ALL'] = res[0]?.total_count
            obj[key.k] = key.v;
       }
       this.countList = obj;
    }
  })
  }

  getAppById(id:any){
    this.loading =true
    this.WebApiService.getAppById(id).subscribe((res:any)=>{
     this.candidate = [res];
     this.loading=false;
     let shCopy = this.statusArr.slice();
     let index = shCopy.indexOf(res.status);
     if(index >= 0){
      shCopy.splice(index, 1)
      this.finalStatusArr = shCopy;
     }
     else{
      this.finalStatusArr = this.statusArr;
     }
      })
  }

  getApplicationByStatus(status:any){
   this.filter.status = status;
   this.WebApiService.getApplicationByStatus(this.filter).subscribe((res:any)=>{
   this.applicationList =res.data
   this.total = res.total
   for (const ap of this.applicationList) {
    let today:any = new Date();
    let otherDate:any = new Date(ap.tatEnd);
    let diffTime = Math.abs(today - otherDate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (today > otherDate) {
      ap.tatStatus = `${diffDays} days delayed`;
    } else if (today < otherDate) {
      ap.tatStatus = `${diffDays} days left`;
    } else {
      ap.tatStatus = `last day of tat`;
    }
     }
     if(this.applicationList[0]?._id){
      this.getAppById(this.applicationList[0]?._id);
    }
    else{
      this.candidate = [];
    }
   })
  }

  async applyFilter(data:any){
    this.filter.clientId = data.clientId;
    this.filter.created = data.created;
    await this.searchCount();
    this.WebApiService.getApplicationByFilter(this.filter).subscribe((res:any)=>{
    this.applicationList =res.data
    this.total = res.total
    for (const ap of this.applicationList) {
     let today:any = new Date();
     let otherDate:any = new Date(ap.tatEnd);
     let diffTime = Math.abs(today - otherDate);
     let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
     if (today > otherDate) {
       ap.tatStatus = `${diffDays} days delayed`;
     } else if (today < otherDate) {
       ap.tatStatus = `${diffDays} days left`;
     } else {
       ap.tatStatus = `last day of tat`;
     }
      }
      if(this.applicationList[0]?._id){
        this.getAppById(this.applicationList[0]?._id);
      }
      else{
        this.candidate = [];
      }
    })
   }

  async searchApp(e:any){
    // console.log(e.target.value);
   this.filter.search = e.target.value
   if(e.target.value){
    await this.searchCount()
    this.WebApiService.getApplicationBySearch(this.filter).subscribe((res:any)=>{
      this.applicationList =res.data
      this.total = res.total
      for (const ap of this.applicationList) {
        let today:any = new Date();
        let otherDate:any = new Date(ap.tatEnd);
        let diffTime = Math.abs(today - otherDate);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (today > otherDate) {
          ap.tatStatus = `${diffDays} days delayed`;
        } else if (today < otherDate) {
          ap.tatStatus = `${diffDays} days left`;
        } else {
          ap.tatStatus = `last day of tat`;
        }
         }
         if(this.applicationList[0]?._id){
          this.getAppById(this.applicationList[0]?._id);
        }
        else{
          this.candidate = [];
        }
      })
   }
   else{
    if(this.loginType == 1){
      this.getAllApplication();
      this.getApplicationCountByStatus();
    }
    else if(this.loginType == 4 && this.teamName == "Case Intitation Team"){
      this.getAllApplicationV2();
      this.getApplicationCountByStatusV2();
    }
    else if(this.loginType ==4 && this.teamName == "Processing Team"){
      this.getAllApplicationByEmp();
      this.getApplicationCountByStatusByEmp();
    }
   }
  }

  searchCount(){
    this.WebApiService.getCountBySearch(this.filter).subscribe((res:any)=>{
      console.log(res.length);
      const obj={};
      if(res.length > 0){
        for (const key of res[0].count_per_field) {
          obj['ALL'] = res[0].total_count
          obj[key.k] = key.v;
     }
     this.countList = obj;
      }
      else{
        obj['ALL'] = 0;
        obj['NEW'] = 0;
        obj['INPROGRESS'] = 0;
        obj['HOLD'] = 0;
        obj['COMPLETED'] = 0;
        obj['STOPPED'] = 0;
        this.countList = obj;

      }

      })
  }
  uploadCsv(e:any){
    const formData = new FormData();
    formData.append('image',e.target.files[0]);
    this.WebApiService.uploadImage(formData).subscribe((res: any) => {
       if(res.status == "ok"){
         this.formExcelData.file = res.imageName
         alert('file uploaded successfully');
        }
        else{
         alert("something went wrong")
        }
     });
  }
  uploadCsvv2(e:any){
    const formData = new FormData();
    formData.append('image',e.target.files[0]);
    this.WebApiService.uploadImage(formData).subscribe((res: any) => {
       if(res.status == "ok"){
         this.formExcelDatav2.file = res.imageName
         alert('file uploaded successfully');
        }
        else{
         alert("something went wrong")
        }
     });
  }

  uploadData(data:any){
    this.WebApiService.uploadApplicationExcel(data).subscribe((res: any) => {
      console.log(res);
      if(res.status == "ok"){
        alert('Bulk Upload Success');
        location.reload();
      }
      else{
        alert('Upload Failed');
      }
    });
  }

  uploadDataV2(data:any){
    this.WebApiService.updateApplicationExcel(data).subscribe((res: any) => {
      console.log(res);
      if(res.status == "ok"){
        alert('Bulk Upload Success');
        location.reload();
      }
      else{
        alert('Upload Failed');
      }
    });
  }

  getProcess(e:any){
   let pro = this.clientList.filter((x=>x._id == e.target.value));
   this.clientprocessList = pro[0].processList;

  }
  resetFilter(){
    this.filterData = {};
  }



  openSmallModal( smallModalContent ) {
    this.modalService.open( smallModalContent, { size : 'sm' } );
  }
  openSmallModalV2( statusMContent,id:any,data:any ) {
    this.statusData._id = id
    this.statusData.candidateName = data.candidateName;
    this.statusData.fatherName = data.fatherName;
    this.statusData.dob = data.dob;
    this.statusData.candidateName = data.candidateName;

    this.modalService.open( statusMContent, { size : 'sm' } );
  }
  openSmallModalV3( reportMcontent,id:any ) {
    this.reportData._id = id
    this.modalService.open( reportMcontent, { size : 'sm' } );
  }

  getImage(name:any,mdl:any){
    this.modalService.open( mdl, { size : '' } );
    let url = `${this.imageUrl}${name}`;
    // console.log(name.includes('.png'));
    if(name.includes('.pdf')){
      this.pdfCounter = true;
      this.imageCounter = false;
      this.fileString = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    else if(name.includes('.jpg') || name.includes('JPG') || name.includes('.png') || name.includes('.PNG') ){
      this.imageCounter = true;
      this.pdfCounter = false
      this.fileString = url;
    }

  }

  deleteApplication(e:any){
    let prompt = confirm("Are you sure you want to delete");
    if(prompt){
      let val = {
        _id:e,
        isActive:false
      }
      this.WebApiService.insertApplication(val).subscribe((res: any) => {
       // console.log(res);
       if(res.status == "ok"){
         alert(res.msg);
         if(this.loginType == 1){
           this.getAllApplication();
           this.getApplicationCountByStatus();
         }
         else if(this.loginType == 4 && this.teamName == "Case Intitation Team"){
           this.getAllApplicationV2();
           this.getApplicationCountByStatusV2();
         }
         else if(this.loginType ==4 && this.teamName == "Processing Team"){
           this.getAllApplicationByEmp();
           this.getApplicationCountByStatusByEmp();
         }
         else if(this.loginType == 3){
           this.filter.clientId = this.loginId
           this.getAllApplication();
           this.getApplicationCountByStatus();
         }
       }
       else{
         alert(res.msg);
       }

     });
    }


  }
  lockApplication(e:any){
    let prompt = confirm("Are you sure you want to lock");
    if(prompt){
      let val = {
        _id:e,
        isActive:false
      }
      this.WebApiService.insertApplication(val).subscribe((res: any) => {
       // console.log(res);
       if(res.status == "ok"){
         alert(res.msg);
         if(this.loginType == 1){
           this.getAllApplication();
           this.getApplicationCountByStatus();
         }
         else if(this.loginType == 4 && this.teamName == "Case Intitation Team"){
           this.getAllApplicationV2();
           this.getApplicationCountByStatusV2();
         }
         else if(this.loginType ==4 && this.teamName == "Processing Team"){
           this.getAllApplicationByEmp();
           this.getApplicationCountByStatusByEmp();
         }
         else if(this.loginType == 3){
           this.filter.clientId = this.loginId
           this.getAllApplication();
           this.getApplicationCountByStatus();
         }
       }
       else{
         alert(res.msg);
       }

     });
    }


  }

  uploadReportFile(e:any){
    if(e.target.files[0].type == 'application/pdf'){
      const formData = new FormData();
      formData.append('image',e.target.files[0]);
      formData.append('type','return');
      this.WebApiService.uploadImage(formData).subscribe((res: any) => {
         if(res.status == "ok"){
           alert('upload success');
           this.fileData.reportFile = `${this.imageUrl}${res.imageName}`;
          }
          else{
           alert("something went wrong")
          }
       });
    }
    else{
      alert('Only Pdf File allowed!!');
      e.target.value = '';
    }

  }

  SubmitReport(id:any){
    this.fileData._id = id;
    this.WebApiService.insertApplication(this.fileData).subscribe((res: any) => {
      // console.log(res,'---');
      if(res.status == "ok"){
        alert(res.msg);
        this.getAppById(id);
       }
       else{
        alert("something went wrong")
       }
    });
  }

  uploadMultipleReportFile(event:any) {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
  }

  // SubmitMultipleReport() {
  //   for (let i = 0; i < this.selectedFiles.length; i++) {
  //      this.uploadMultipleFiles(this.selectedFiles[i],this.selectedFiles[i].name.split(".")[0]);
  //   }
  //   // console.log("media uploaded");
  //   alert("bulk upload Success");
  //   location.reload();

  // }

  SubmitMultipleReport() {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      let fileName = this.selectedFiles[i].name.split(".")[0];
      fileName = fileName.split('-')[0];
      this.uploadMultipleFiles(this.selectedFiles[i], fileName);
      console.log(`media uploaded-${i + 1}`);
    }
    alert("Bulk upload successful");
    location.reload();
  }


  uploadMultipleFiles(file,empId:any){
    const formData = new FormData();
    formData.append('image',file);
    formData.append('empId',empId);
    formData.append('url',this.imageUrl);
    formData.append('type','return');
    this.WebApiService.uploadMultipleImage(formData).subscribe((res: any) => {
      console.log(res);
    },((error)=>{
      console.log(error);

    }));
  }

}

        // -------------------------------------------------
        // ------------------------
        // ---- if the above code stops working use the below code
        // ------------------------------

// SubmitMultipleReport() {
//   for (let i = 0; i < this.selectedFiles.length; i++) {
//      let fileName = this.selectedFiles[i].name.split(".")[0];
//      fileName = fileName.split('-')[0];
//      this.uploadMultipleFiles(this.selectedFiles[i],fileName,i);
//     console.log('media uploaded-${i+1}');
//   }

// }

// uploadMultipleFiles(file,empId:any,index:any){
//   const formData = new FormData();
//   formData.append('image',file);
//   formData.append('empId',empId);
//   formData.append('url',this.imageUrl);
//   formData.append('type','return');
//   this.WebApiService.uploadMultipleImage(formData).subscribe((res: any) => {
//     if(this.selectedFiles.length == index+1){
// alert("bulk upload Success");
//   location.reload();
//     }
//     // console.log(res);
//   },((error)=>{
//     console.log(error);

// }));
// }
